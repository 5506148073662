<template>
  <div class="login ml-auto mr-auto" style="width:90%;">
    <div style="text-align:center;">
      <img alt="Display Dog Logo" width="50%;" src="@/assets/img/displaydog-logo-500.svg" />
      <h1 style="padding-bottom:12px;padding-top:32px;"></h1>
      <device-auth v-if="!loading" title="Device Activation" @activate="activate( $event )" @cancel="appHome()" :activationCode="activationCode" :isActive="!loading" :message="message" style="display:inline-block;"/>
    </div>
    <div style="color:red;font-weight:800;font-size:1.5em;text-align:center;">{{errorMessage}}</div>
  </div>
</template>

<script>
// @ is an alias to /src
import DeviceAuth from '@/components/DeviceAuth.vue';

import AuthenticationService from "@/services/AuthenticationService.js";
import GeneralService from "@/services/GeneralService.js";

export default {
  name: 'activate',
  components: {
    DeviceAuth
  },
  data() {
    return {
      activationCode: null,
      loading       : false,
      message       : "Stay On THIS SCREEN - Contact your System Administrator and provide the above activation code. ",
      errorMessage  : "",
      token         : "",
      loginMessage  : "",
      domain        : "",
      email         : "",
      deviceId      : "",
      inActivate    : false,
      activationCode: "",
      pageInterval  : null
    }
  },
  destroyed() {
    console.log("Destroyed()" );
  },
  beforeDestroy() {
    console.log( "beforeDestroy()");
    this.stopTimer();
  },
  created() {
    this.activationCode = this.makeDashCode((Math.floor((Math.random()*999999)+100000000)).toString(),3);
  },
  mounted() {
    this.stopTimer();
    this.pageInterval = setInterval( () => {
      this.activate({});
    },15000);
  },
  methods: {
    makeDashCode(id, size = 5) {
      var o = "";
      for (var i = 0; i < id.length; i += size) {
        o = o + (id.substring(i, i + size) + "-");
      }
      return o.substring(0, o.length - 1); // eat the trailing dash '-' on our way out.
    },
    stopTimer() {
    clearInterval( this.pageInterval );
      console.log( "Stopping Timer" );
//       var interval_id = setInterval("", 9999); 
//       for (var i = 1; i < interval_id; i++) {
// console.log( "Stopping timer " , i);
//         clearInterval(i);
//       }
    },
    async activate( creds ) {
      const t = this;
      const r = this.$root;

      if( t.inActivate ) {
        return;
      }

      t.errorMessage = "";
      t.inActivate = true;

      localStorage.clear();   // todo: be better at WHAT we remove here...

      const deviceAuthorizationData = {
        activationCode: this.activationCode
      };

      let response;
      this.token = "Sending Request...";  // todo: refactor this to the message area.

      try {
        response  = await GeneralService.activateDevice(deviceAuthorizationData);
      } catch (error) {
        t.inActivate = false;
        // t.errorMessage = error.message;
        return;
      }

      if (response.status === 200) {
        this.stopTimer();
        let deviceData = response.data;
        t.$root.activate(deviceData);
        if( t.$route.query.redirect ) {
          t.$router.push( t.$route.query.redirect );
        } else {
          t.inActivate = false;
          t.appHome();
        }
      } else {
        if( response.data && response.data.message ) {
          t.errorMessage = response.data.message;
        }
      }
    },
    appHome() {
      this.$router.push( "/");
    },
    home() {
      let t =this;
      t.$router.push("/");       // jump to the APP root to start his show
    }
  }
}
</script>
<style language="scss" scoped>

  .login {
    overflow:hidden;
    padding-top:8em;
    padding-bottom:100%;

  }


</style>