<template>
  <div class="auth" >
    <h4>{{ title }}</h4>
    <form >
      <div>
        <label class="auth-label" for="deviceId">Activation Code</label>
        <div>
          <span style="font-weight:bold;font-size:1.25em;">{{activationCode}}</span>
        </div>
      </div>
      <div style="margin-top:20px;">
        <b-button v-if="isActive" class="btn btn-sm btn-fill" style="margin-left:1em;border-radius:1em;padding-left:2em;padding-right:2em;" variant="danger"  @click="cancelClick">Cancel</b-button>
        <!-- <b-button v-if="isActive" class="btn btn-sm btn-fill" style="margin-left:1em;border-radius:1em;padding-left:2em;padding-right:2em;" variant="success"  @click="activate()">{{buttonTitle}}</b-button> -->
        <div v-if="!isActive">Authorizing, please stand by...</div>
      </div>
      <p style="display:inline-block;margin-top:2em;width:280px;text-align:center;font-size:.8em;font-style:italic;">
        {{message}}
    </p>
    </form>
    
  </div>
</template>

<script>
export default {
  name: 'deviceAuth',
  props: {
    activationCode: { type: String, default: "" },
    isActive   : { type: Boolean, default: true },
    title      : { type: String, default: "" },
    buttonTitle: {type:String, default: "Activate Device" },
    message    : {type:String, default: "Contact your System Administrator and provide the above activation code."}
  },
  data() {
    return {
    } 
  },
  computed: {
  },
  mounted() {
    isActive: true
  },
  methods: {
    cancelClick() {
      this.$emit( "cancel" );
    },
    activate() {
      let creds = {
        activationCode: this.activationCode
      }
      this.$emit( "activate" , creds );
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .auth {
    display:inline-block;
    vertical-align:middle;
    border-color: #4056a1;
    border-style:solid;
    border-width:2pt;
    border-radius:50%;  
    padding-top:5em;
    width: 400px;
    height: 400px;
    -webkit-box-shadow: 0px 0px 10px 3px #000000; 
    box-shadow: 0px 0px 10px 3px #000000;
  }

  .auth-label {
    margin:.5em 0 0 0 ;
    padding:0;
    font-size:.8em;
  }

@media only screen and (max-width : 399px) {
  .auth {
    width:325px;
  }
}


</style>
